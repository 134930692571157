@tailwind base;
@tailwind components;
@tailwind utilities;


#registration-fields {
  @apply hidden ;
}

.hero-rich-text p {
    @apply mb-3 sm:mb-5 md:mb-8;
}

.hero-rich-text p:last-child {
    @apply mb-0;
}

.rich-text {
    @apply text-sm sm:text-base md:text-lg text-stone-600 leading-loose dark:text-gray-300;
}

.rich-text p{
    @apply mb-5 md:mb-8 leading-loose;
}


.richt-text-data-protection{
    @apply text-base text-black  leading-relaxed  dark:text-gray-300;
}

.richt-text-data-protection p{
    @apply mb-5 leading-relaxed text-sm;
}

.richt-text-data-protection p:last-child{
    @apply mb-0;
}
.richt-text-data-protection h3{
    @apply mb-5 leading-relaxed font-bold text-lg ;
}

.richt-text-data-protection strong{
    @apply mb-5 md:mb-8 ;
}

.richt-text-data-protection a{
    @apply underline;
}

.rich-text-workshop-success-message p{
    @apply mb-5 leading-relaxed;
}

.rich-text-workshop-success-message p:last-child{
    @apply mb-0;
}





.richt-text-modal p{
    @apply mb-5 leading-relaxed text-sm;
}

.richt-text-modal p:last-child{
    @apply mb-0;
}



.required::after {
    content: ' *';
    color: red;
}



@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(/public/fonts/Roboto/Roboto-Thin.woff2) format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/public/fonts/Roboto/Roboto-Light.woff2) format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/Roboto/Roboto-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/public/fonts/Roboto/Roboto-Medium.woff2) format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/public/fonts/Roboto/Roboto-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(/public/fonts/Roboto/Roboto-Black.woff2) format('woff2');
}





.main-bg {
    background: radial-gradient(51.01% 50.79% at 48.99% 49.21%, #137E5B 0%, #137E5B 30.18%, #0E5E43 97.66%, #0E5E43 97.84%, #0E5E43 100%);
}




